/* eslint-disable @typescript-eslint/dot-notation */
/**
 * When we think of a particular color name, we mean this hex code.
 * These keys can be used to describe color themes.
 */
export const COLOR_VALUES = {
  white: '#ffffff',
  black: '#000000',
  solitude: '#f7f9fa',
  'dark-solitude': '#eaedf1',
  silver: '#bac1c8',
  'silver-chalice': '#9ba3ad',
  'gun-powder': '#4c545d',
  'mine-shaft': '#272b2f',
  'light-steel-blue': '#e2ebf5',
  nepal: '#accdf2',
  'steel-blue': '#6babf3',
  'san-marino': '#388aed',
  'dark-san-marino': '#126cd3',
  'smugglers-cove': '#1755a0',
};

/**
 * This is a generic, non-branded theme.
 * This theme should be always be applied to the page.
 */
export const THEME_BASE = {
  '--color-white': COLOR_VALUES['white'],
  '--color-black': COLOR_VALUES['black'],
  '--color-dark-solitude': COLOR_VALUES['dark-solitude'],
  '--color-silver': COLOR_VALUES['silver'],
  '--color-silver-chalice': COLOR_VALUES['silver-chalice'],
  '--color-gun-powder': COLOR_VALUES['gun-powder'],
  '--color-mine-shaft': COLOR_VALUES['mine-shaft'],
  '--color-solitude': COLOR_VALUES['solitude'],
  '--color-light-steel-blue': COLOR_VALUES['light-steel-blue'],
  '--color-nepal': COLOR_VALUES['nepal'],
  '--color-steel-blue': COLOR_VALUES['steel-blue'],

  /**
   * The following colors do not match their names. This is to provide a generic palette that is not
   * aware of the CallRail brand.
   * */
  '--color-san-marino': COLOR_VALUES['gun-powder'],
  '--color-dark-san-marino': COLOR_VALUES['mine-shaft'],
  '--color-smugglers-cove': COLOR_VALUES['mine-shaft'],
};

/**
 * This is a generic, non-branded theme.
 * This theme is applied on top of the gneric theme, so it only needs to describe the
 * colors that are different than the defaults described above.
 */
export const THEME_CALLRAIL = {
  '--color-san-marino': COLOR_VALUES['san-marino'],
  '--color-dark-san-marino': COLOR_VALUES['dark-san-marino'],
  '--color-smugglers-cove': COLOR_VALUES['smugglers-cove'],
};
