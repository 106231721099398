import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CognitoCookieService } from '@callrail/looky/core';
import { LocationService } from '@callrail/looky/util';
import { RedirectService } from '../../services/redirect/redirect.service';

@Component({
  selector: 'anbs-amplify-redirect',
  template: '',
})
export class AmplifyRedirectComponent implements OnInit {
  @Input() redirectType = 'auth';
  @Input() remember = false;
  @Output() redirect = new EventEmitter<boolean>();

  constructor(
    private locationService: LocationService,
    private cognitoCookieService: CognitoCookieService,
    private redirectService: RedirectService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    // Delete the user signed out cookie
    this.cognitoCookieService.resetUserSignedOut();

    if (this.redirectType === 'dev') {
      this.redirectTo('/');
      return;
    }

    this.redirectService.redirect(this.route.snapshot.queryParamMap).subscribe({
      next: ({ redirect_to }) => {
        this.redirectTo(redirect_to);
      },
      error: () => {
        this.redirectTo('/authenticate/sign_out');
      },
    });
  }

  private redirectTo(redirect: string): void {
    this.locationService.go(redirect, true);
  }
}
